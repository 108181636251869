
import { ref } from 'vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
@Component({
  computed: {
    ...mapGetters('BusinessModule', ['getBusinessFrameworks']),
    ...mapGetters('AuthModule', ['getCompanyIdSelected', 'getCompanyFrameworksSelected']),
  },
})
export default class NewSidebar extends Vue {
  @Prop({ required: true }) private modules!: any[]
  @Prop({ required: true }) private loading!: boolean
  public getCompanyIdSelected!: string
   public pinSidebar: boolean = false;
  public isPinIcon: boolean = false;

  private getIcon(alias: string) {
    switch (alias) {
      case 'command-center':
        return require('../../../assets/icons/icon-home.svg')
      case 'manage':
        return require('../../../assets/icons/icon-manage.svg')
      case 'evidences':
        return require('../../../assets/icons/icon-evidence.svg')
      case 'monitoring':
        return require('../../../assets/icons/icon-monitoring.svg')
      case 'ethical-hacking':
        return require('../../../assets/icons/icon-ethical.svg')
      case 'training':
        return require('../../../assets/icons/icon-training.svg')
      case 'soc':
        return require('../../../assets/icons/icon-soc.svg')
      case 'resources':
        return require('../../../assets/icons/icon-resources.svg')
      case 'settings':
        return require('../../../assets/icons/icon-settings.svg')
      case 'profile':
        return require('../../../assets/icons/icon-profile.svg')
      case 'compliance':
        return require('../../../assets/icons/icon-compliance.svg')
      case 'implementation':
        return require('../../../assets/icons/sidebarIcons/implementation-icon.svg')
      case 'staff':
        return require('../../../assets/icons/sidebarIcons/staff-icon.svg')
      case 'trust-report':
        return require('../../../assets/icons/sidebarIcons/report-icon.svg')
      case 'tasks':
        return require('../../../assets/icons/sidebarIcons/task-icon.svg')
      case 'documents':
        return require('../../../assets/icons/sidebarIcons/documents-icon.svg')
      case 'assets':
        return require('../../../assets/icons/sidebarIcons/asset-icon.svg')
      case 'risks':
        return require('../../../assets/icons/sidebarIcons/risk-icon.svg')
      case 'access':
        return require('../../../assets/icons/sidebarIcons/access-icon.svg')
      case 'audits':
        return require('../../../assets/icons/sidebarIcons/audit-icon.svg')
      case 'app-security':
        return require('../../../assets/icons/sidebarIcons/application-icon.svg')
      case 'domains':
        return require('../../../assets/icons/sidebarIcons/domain-icon.svg')
      case 'devices':
        return require('../../../assets/icons/sidebarIcons/device-icon.svg')
      case 'pentesting':
        return require('../../../assets/icons/sidebarIcons/pentesting-icon.svg')
      case 'trainings':
        return require('../../../assets/icons/sidebarIcons/trainings-icon.svg')
      case 'incidents':
        return require('../../../assets/icons/sidebarIcons/incident-icon.svg')
      case 'files':
        return require('../../../assets/icons/sidebarIcons/file-icon.svg')
      case 'history':
        return require('../../../assets/icons/sidebarIcons/history-icon.svg')
      case 'cloud-security':
        return require('../../../assets/icons/sidebarIcons/cloud-icon.svg')
        case 'onboarding':
        return require('../../../assets/icons/sidebarIcons/icon-onboarding.svg')
      default:
        return require('../../../assets/icons/sidebarIcons/bolt-icon.svg')
    }
  }
  public toggleSidebar(state: boolean) {
    if (!this.isPinIcon) {
      this.pinSidebar = state;
    }
  }

  public togglePinIcon() {
    this.isPinIcon = !this.isPinIcon;
    this.pinSidebar = this.isPinIcon;

    if (this.isPinIcon) {
      localStorage.setItem('isPinIcon', JSON.stringify(this.isPinIcon));
    } else {
      localStorage.removeItem('isPinIcon');
    }
  }


  private getNameCompany() {
    return this.$store.getters['AuthModule/getCompanyNameSelected']
  }
  public openKey: string | null = null
  public selectedItem: string | null = null
  private toggleOpen(key: string) {
    if (this.openKey === key) {
      this.openKey = null
    } else {
      this.openKey = key
    }
  }
  mounted() {
    this.setInitialOpenState()
    this.initializeFrameworkSelected()
    let savedPinState = localStorage.getItem('isPinIcon')
    if (savedPinState !== null) {
      this.isPinIcon = JSON.parse(savedPinState)
      this.pinSidebar = this.isPinIcon
    }
  }

  @Watch('modules', { immediate: true, deep: true })
  onModulesChange() {
    this.setInitialOpenState()
  }

  private setInitialOpenState() {
    const currentPath = this.$route.path
    this.modules.forEach((module) => {
      if (module.path === currentPath) {
        this.openKey = module.alias
        this.selectedItem = module.alias
      }
      module.items?.forEach((item: any) => {
        if (item.path === currentPath) {
          this.openKey = module.alias
          this.selectedItem = item.alias
        }
      })
    })
  }

  private isOpen(key: string): boolean {
    return this.openKey === key
  }
  private selectItem(alias: string, path?: string, name?: string, isExternalRoute?: boolean) {
    this.selectedItem = alias
    if (name) this.$mixpanel.track(`Ingresó a ${name}`)
    if (!path) return

    const businessId = this.$store.state.BusinessModule.BusinessState.businessSelected.id
    if (alias === 'company-profile' && path === '/app/clients/') {
      path = `${path}${businessId}/filebusiness`
    } else if (alias === 'users' && path === '/app/clients/') {
      path = `${path}${businessId}/contacts`
    }
    if (isExternalRoute) {
      window.location.href = path
    } else if (
      path &&
      (path.includes('/startup') || path.includes('/app') || path === '/compliance')
    ) {
      this.$router.push({ path })
    } else {
      window.location.href = `${window.location.origin}${path}`
    }
  }
  private enter(el: HTMLElement) {
    const height = el.scrollHeight
    el.style.maxHeight = '0'
    requestAnimationFrame(() => {
      el.style.transition = 'max-height 0.2s ease-in-out'
      el.style.maxHeight = `${height}px`
    })
  }
  private leave(el: HTMLElement) {
    el.style.maxHeight = `${el.scrollHeight}px`
    requestAnimationFrame(() => {
      el.style.transition = 'max-height 0.2s ease-in-out'
      el.style.maxHeight = '0'
    })
  }
  private get isClientsRoute(): boolean {
    return this.$route.path === '/app/clients'
  }
  get vuex() {
    return JSON.parse(localStorage.getItem('vuex') || '{}')
  }
  get rolesVuex(): string[] {
    return this.vuex.AuthModule.AuthState.role
  }
  isModuleAbledByRole(module: string): boolean {
    return this.rolesVuex.some((role: string) => role.includes(module))
  }
  private initializeFrameworkSelected() {
    const selectedFramework = localStorage.getItem('selectedFramework')
    if (selectedFramework) {
      this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', JSON.parse(selectedFramework))
    }
  }
}
