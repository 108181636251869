
import { ref } from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'
import Avatar from 'vue-avatar'
import SideNavItemComponent from '@/components/V2/Sidebar/Index.vue'
import NewSidebar from '@/components/V2/NewSidebar/Index.vue'
import SearchBar from '@/components/SearchBar/Index.vue'
import DialogFinishActivity from '@/components/DialogFinishActivity/Index.vue'
import { Intercom } from '@mathieustan/vue-intercom'
import type { ConfigCompanyEnable } from '@/utils/ConfigCompaniesEnabled'
import store from '@/store/index'

import { MimeTypes } from "@/shared/mimeTypes"
import ImgViewer from "@/components/FileViewer/ImgViewer.vue";

@Component({
  components: {
    SideNavItemComponent,
    NewSidebar,
    Avatar,
    SearchBar,
    DialogFinishActivity,
    ImgViewer,
  },
  methods: {
    ...mapActions('AuthModule', ['Logout']),
  },
  computed: {
    ...mapState('PolimorficModule', ['PolimorficState']),
    ...mapState('AuthModule', ['AuthState', 'getUserId']),
    ...mapGetters('BusinessModule', ['getBusinessFrameworks']),
    ...mapGetters('PolimorficModule', ['getStatusModal']),
    ...mapGetters('SteppersModule', ['getModalStatus']),
  },
})
export default class CoreContainerComponent extends Vue {
  [x: string]:
  /* __placeholder__ */
  any
  private username = this.$store.state.AuthModule.AuthState.email
  private intercom: any
  private rebootIntercom(user: any) {
    this.intercom.shutdown()
    if (this.intercom.isBooted) return
    this.intercom.boot(user)
  }
  private modules = []
  public loadingSidebar: boolean = true
  public featureFlag = ref()
  public name: string = ""
  public showViewer: boolean = false
  public evidenceSelected: any = null
  public dataSearch: any[] = []
  public disableIntercom: boolean = localStorage.getItem('disabledIntercom') === 'true';

  mounted() {
    this.intercom = new Intercom({ appId: process.env.VUE_APP_INTERCOM })
    const user = {
      user_id: this.$store.state.AuthModule.AuthState.id,
      name: this.$store.state.AuthModule.AuthState.name,
      email: this.$store.state.AuthModule.AuthState.email,
      user_hash: this.$store.state.AuthModule.AuthState.intercom_hmac_code,
    }
    if(!this.disableIntercom) {
      if (!this.intercom.ready) {
        this.intercom.once('ready', () => this.rebootIntercom(user))
      } else {
        this.rebootIntercom(user)
      }

    }

    this.initGrowthBook()
      .then((growthBook: any) => {
        if (!growthBook) {
          console.warn('GrowthBook failed to initialize. Feature flags and experiments not active.')
          return
        }

        growthBook.setAttributes({
          loggedIn: true,
          country: 'france',
          employee: false,
          id: 'user-abc123',
        })

        const evaluatedBannerText = growthBook.getFeatureValue('enable-log-menu', '')
        if (typeof evaluatedBannerText !== 'undefined') {
          this.featureFlag = evaluatedBannerText
        }
      })
    this.loadingSidebar = true
    this.$store.dispatch('BusinessModule/getSidebars').then((data)=> {
      this.modules = data.data.data
      this.loadingSidebar = false
    })
  }

  private byRole(role: string) {
    return this.$store.state.AuthModule.AuthState.role.includes(role)
  }

  private destroyModal() {
    this.$store.commit('SteppersModule/SET_OPEN_MODAL', false)
    this.$store.commit('SteppersModule/SET_FINISH_INFO', {})
  }

  private async logout(){
    this.$store.commit('AuthModule/SET_TOKEN_AUTH')
    window.location.replace(`${process.env.VUE_APP_AUTH_BASE_URL}/logout`)
  }

  validateAccessBeta(moduleName: string): boolean | undefined {
    const BusinessId = this.$store.state.AuthModule.AuthState.company_id
    const companyId: string = BusinessId.toString().trim()
    const companies = localStorage.getItem('enabledCompanies')
    if (companies === null) return false
    const config: ConfigCompanyEnable[] = JSON.parse(companies)
    const module = config.find((m) => m.module === moduleName)

    if (module === null) return false

    if (module?.isEnabledAll) return true
    else return module?.companiesID.includes(companyId)
  }
  private redirectPath() {
    let implementationCompleted = false
    if (localStorage.getItem('implementationCompleted'))
      implementationCompleted = localStorage.getItem('implementationCompleted') === 'true'
    if (
      this.$store.state.AuthModule.AuthState.role.includes('Admin CMS') ||
      this.$store.state.AuthModule.AuthState.role.includes('Implementador') ||
      this.$store.state.AuthModule.AuthState.role.includes('Customer Success')
    ) {
      this.$router.push('/app/clients')
    } else if (implementationCompleted) {
      window.location.replace(process.env.VUE_APP_URL_COMMAND_CENTER || '')
    } else {
      this.$router.push('/startup/activity')
    }
  }

  async getData() {
    await store.dispatch('UniversalSearchModule/getInfoBySearch', this.name);
    const response = store.getters['UniversalSearchModule/getDataSearch']
    if (response?.statusCode === 200) {
      this.dataSearch = response.data;
    } else {
      this.dataSearch = [];
    }
  }

  getInfoBySearch() {
      this.dataSearch = [];
      if (this.name.length > 2) {
        this.getData();
      }
    }
    deleteName() {
      this.name = "";
      this.evidenceSelected = null;
      this.getInfoBySearch();
    }
    async openViewer(item: any) {
      
      const imageExtensions = ["image/jpeg", "image/png", "application/pdf"];
      const extension = item.type.toLowerCase();
      if (imageExtensions.includes(extension)) {
        this.showViewer = true;
        this.evidenceSelected = item;
      } else {
        window.open(item.path, "_blank");
      }
    }
    getIconPath(type: string) {
      switch (type) {
        case MimeTypes.JPEG:
          return require('@/assets/icons/icon-png.svg')
        case MimeTypes.PNG:
          return require('@/assets/icons/icon-png.svg')
        case MimeTypes.GIF:
          return require('@/assets/icons/icon-png.svg')
        case MimeTypes.PDF:
          return require('@/assets/icons/icon-pdf.svg')
        case MimeTypes.PPT:
          return require('@/assets/icons/icon-ppt.svg')
        case MimeTypes.EXCEL:
          return require('@/assets/icons/icon-xlsx.svg')
        case MimeTypes.CSV:
          return require('@/assets/icons/icon-csv.svg')
        default:
          return require('@/assets/icons/icon-png.svg')
      }
    }
    closeViewer() {
      this.showViewer = false;
    }
}
